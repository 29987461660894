export const websiteLink = 'http://luckyeth.xyz/';
export const tgLink = 'https://t.me/LKYcoineth';
export const xLink = 'https://x.com/LKYcoineth';
export const ethScanner = '#';
export const dexScreenerLink = '#';
export const dexToolsLink = '#';
export const uniSwapLink = '#';
export const buySellTax = '0%';
export const supply = '1 Billion';
export const CA = 'TBA';
